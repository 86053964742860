<template>
  <c-table
    ref="table"
    :title="'위험형태(' + popupParam.riskTypName + ')별 원인 목록'"
    :columns="grid.columns"
    :gridHeight="grid.height"
    :data="grid.data"
    :filtering="false"
    :columnSetting="false"
    :usePaging="false"
    :editable="editable"
    :selection="popupParam.type"
    @rowDblclick="rowDblclick"
    rowKey="ramRiskTypeCauseId"
  >
    <!-- 버튼 영역 -->
    <template slot="table-button">
      <q-btn-group outline >
        <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
      </q-btn-group>
    </template>
  </c-table>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'kpsr-cause-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        // 대상공정의 구분을 확인 후 해당 구분에 맞게 목록을 조회 (회분식, 연속식)
        processType: '',
        // 위험형태 key를 제공받아 해당 key를 토대로 목록을 조회토록 설정
        ramRiskTypeId: '',
        riskTypName: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '650px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      this.grid.height = (this.contentHeight - 50) + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskTypeCause.list.url
      // code setting
      this.$comm.getComboItems('MDM_PROCESS_TYPE_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'riskTypeCauseMainName',
            field: 'riskTypeCauseMainName',
            label: '원인(대분류)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'riskTypeCauseSubName',
            field: 'riskTypeCauseSubName',
            label: '원인(소분류)',
            align: 'left',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'processTypes',
            field: 'processTypes',
            label: '공정구분',
            align: 'center',
            style: 'width:80px',
            type: 'multiSelect',
            isObject: true,
            valueText: 'mdmProcessTypeName',
            valueKey: 'mdmProcessTypeCd',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
            sortable: true,
            disableTarget: 'none',
            disableCon: 'O',
          },
        ];
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskTypeId: this.popupParam.ramRiskTypeId
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '원인을 하나 이상 지정하세요.', // 원인을 하나 이상 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          item.riskTypName = this.popupParam.riskTypName;
        })
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
